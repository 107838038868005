import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextWorkFrame = makeShortcode("TextWorkFrame");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`dj sniff`}</h1>
    <p>{`テキストと音により構成された約8分の音声作品です。dj sniffさんは、杉浦さんの作品に呼応して、ターンテーブルでレコードの溝を消すという作業を行いました。針の代わりに取り付けられたカッターナイフ、紙ヤスリ、そして消しゴムは、レコードの表面を削りながらノイズ音と共振音を鳴らします。また、時折水を垂らすことで摩擦は増し、ターンテーブルの回転は不規則になっていきます。杉浦さんの写真とも通じる、物質が持つ記憶の手前にあるものにアクセスしようとする試みです。`}</p>
    <TextWorkFrame type="djsniff" mdxType="TextWorkFrame" />
    <iframe width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1113569758&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe><div style={{
      "fontSize": "10px",
      "color": "#cccccc",
      "lineBreak": "anywhere",
      "wordBreak": "normal",
      "overflow": "hidden",
      "whiteSpace": "nowrap",
      "textOverflow": "ellipsis",
      "fontFamily": "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
      "fontWeight": "100"
    }}><a href="https://soundcloud.com/djsniff" title="djsniff" target="_blank" style={{
        "color": "#cccccc",
        "textDecoration": "none"
      }}>djsniff</a> · <a href="https://soundcloud.com/djsniff/sets/a-response-to-atsushi-sugiuras" title="A Response to Atsushi Sugiura&#x27;s Work / 杉浦篤の作品への応答" target="_blank" style={{
        "color": "#cccccc",
        "textDecoration": "none"
      }}>A Response to Atsushi Sugiura&#x27;s Work / 杉浦篤の作品への応答</a></div>
    <Caption before={`作品`} medium={`サウンド、テキスト、写真、2020`} mdxType="Caption">
  《消されることで共振する記憶の手前にあるもの　杉浦篤の作品への応答》
    </Caption>
    <BpHr type={`dot`} color={`invert`} mdxType="BpHr" />
    <LinkButton color={`invert`} to={`/works/atsushi-sugiura`} mdxType="LinkButton">作品: 杉浦篤</LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      